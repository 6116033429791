.spreadWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
}

.inline.inline {
  display: inline;
}
